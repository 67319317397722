module.exports = () => {
  document.addEventListener(
    "play",
    (e) => {
      const audios = document.getElementsByTagName("audio");
      for (let i = 0, len = audios.length; i < len; i += 1) {
        if (audios[i] !== e.target) {
          audios[i].pause();
        }
      }
    },
    true
  );

  const audioAccordions = document.querySelectorAll(".audio-toggle-button");
  const audioWrapper = document.getElementById("audio-players-wrapper");

  for (let i = 0; i < audioAccordions.length; i += 1) {
    audioAccordions[i].addEventListener("click", () => {
      setTimeout(() => {
        const numOpenAccordions = document.querySelectorAll(
          '.audio-toggle-button[aria-expanded="true"]'
        ).length;

        if (numOpenAccordions === 1) {
          const playerWindow = document.getElementById(
            `${audioAccordions[i].getAttribute("aria-controls")}`
          );
          const playerWindowHeight = playerWindow.offsetHeight;
          audioWrapper.style.paddingBottom = `${playerWindowHeight}px`;
        } else {
          audioWrapper.removeAttribute("style");
        }
      }, 5);
    });
  }
};
