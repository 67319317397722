module.exports = () => {
  const langButton = document.querySelectorAll("[data-lang");

  for (let i = 0; i < langButton.length; i += 1) {
    const lang = langButton[i].getAttribute("data-lang");
    langButton[i].addEventListener("click", () => {
      localStorage.setItem("lang", lang);
      if (lang === "en") {
        window.location.href = `/`;
      } else window.location.href = `/${lang}`;
    });
  }
};
