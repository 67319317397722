window.together = {
  changeLang: require("./change-lang.js"),
  addChild: require("./add-child.js"),
  followUps: require("./follow-ups"),
  toggleAudio: require("./audio.js"),

  init: () => {
    //together.initialize();
    together.changeLang();
    together.addChild();
    together.followUps();
    together.toggleAudio();
  },
};

window.onload = together.init;
