module.exports = () => {
  const radioInput = document.querySelectorAll('input[type="radio"]');
  const checkBox = document.querySelectorAll(
    'input[type="checkbox"][data-followup]'
  );
  const selectBox = document.querySelectorAll("select");
  const DATA_KEY = "data-followup";

  // Show follow
  const showFollowup = (control, target) => {
    target.removeAttribute("hidden");
    control.setAttribute("aria-expanded", "true");
    const fields = target.querySelectorAll("input, select, textarea");

    for (let i = 0; i < fields.length; i += 1) {
      fields[i].removeAttribute("disabled");
    }
  };

  // Hide follow up
  const hideFollowup = (control, target) => {
    target.setAttribute("hidden", "");
    control.setAttribute("aria-expanded", "false");
    const fields = target.querySelectorAll("input, select, textarea");
    for (let i = 0; i < fields.length; i += 1) {
      fields[i].setAttribute("disabled", "true");
      if (fields[i].getAttribute("type") === "text") {
        fields[i].value = "";
      }
      if (fields[i].getAttribute("type") === "radio") {
        fields[i].checked = false;
      }
    }
  };

  // Radio
  for (let i = 0; i < radioInput.length; i += 1) {
    radioInput[i].addEventListener("change", () => {
      const name = radioInput[i].getAttribute("name");
      const control = document.querySelectorAll(
        `input[type="radio"][name="${name}"]`
      );
      for (let x = 0; x < control.length; x += 1) {
        const targetId = control[x].getAttribute(DATA_KEY);
        const target = document.getElementById(targetId);

        if (targetId) {
          const controlRadio = document.querySelector(
            `[data-followup=${targetId}]`
          );
          if (controlRadio.checked) {
            showFollowup(controlRadio, target);
          } else {
            hideFollowup(controlRadio, target);
          }
        }
      }
    });
  }

  // Checkbox
  for (let i = 0; i < checkBox.length; i += 1) {
    checkBox[i].addEventListener("change", () => {
      const targetId = checkBox[i].getAttribute("data-followup");
      const target = document.getElementById(targetId);
      if (checkBox[i].checked) {
        showFollowup(checkBox[i], target);
      } else {
        hideFollowup(checkBox[i], target);
      }
    });
  }

  setTimeout(() => {
    for (let i = 0; i < checkBox.length; i += 1) {
      const control = checkBox[i];
      if (control) {
        const targetId = checkBox[i].getAttribute("data-followup");
        const target = document.getElementById(targetId);

        if (control.checked) {
          showFollowup(control, target);
        }
      }
    }
  }, 300);

  // Select boxes
  for (let i = 0; i < selectBox.length; i += 1) {
    selectBox[i].addEventListener("change", () => {
      const selectedItem = selectBox[i].querySelector(":checked");
      const selectedItemTarget = selectedItem.getAttribute(DATA_KEY);
      const selectBoxFollowUpId = selectBox[i].querySelector(`[${DATA_KEY}]`);
      if (selectBoxFollowUpId) {
        const followUpEl = document.getElementById(
          selectBoxFollowUpId.getAttribute(DATA_KEY)
        );

        if (selectedItemTarget) {
          showFollowup(selectBoxFollowUpId, followUpEl);
        } else {
          hideFollowup(selectBoxFollowUpId, followUpEl);
        }
      }
    });
  }

  setTimeout(() => {
    for (let i = 0; i < selectBox.length; i += 1) {
      const control = selectBox[i].querySelector(`option[${DATA_KEY}]`);
      if (control) {
        const selectedVal = selectBox[i].querySelector(":checked").value;
        const target = document.getElementById(control.getAttribute(DATA_KEY));

        if (selectedVal === control.value) {
          showFollowup(control, target);
        }
      }
    }
  }, 300);
};
