module.exports = () => {
  const childFields = document.querySelector("[data-fields='child-fields']");
  const addButton = document.getElementById("add-child-button");
  const incrementSection = document.getElementById("increment-section");
  const limitReachedSection = document.getElementById("limit-reached");

  const LIMIT = 5;

  // Have to use the timeout because we need to filter out sections based on formData when editing
  setTimeout(() => {
    const currentChildCount = document.querySelectorAll(
      "[data-fields='child-fields']"
    ).length;
    if (currentChildCount >= LIMIT) {
      incrementSection.classList.add("display-none");
      limitReachedSection.classList.remove("display-none");
    }
  }, 100);

  const additionalFieldsContainer = document.getElementById(
    "additional-children-container"
  );
  const attrsToReplace = [
    "id",
    "name",
    "for",
    "aria-describedBy",
    "data-print",
  ];

  const resetIds = () => {
    const childSections = document.querySelectorAll(
      "[data-fields='child-fields']"
    );

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.value = "provided";

    for (let b = 0; b < childSections.length; b += 1) {
      const itemsToUpdate = childSections[b].querySelectorAll(
        `[id], [for], [name], [aria-describedby], [data-print]`
      );
      const currentChildNum = b;
      for (let i = 0, l = itemsToUpdate.length; i < l; i += 1) {
        for (let x = 0; x < attrsToReplace.length; x += 1) {
          if (itemsToUpdate[i].getAttribute(attrsToReplace[x])) {
            const oldAttribute = itemsToUpdate[i].getAttribute(
              attrsToReplace[x]
            );
            const oldChildNum = oldAttribute.replace(/child_(\d+)_.+/g, "$1");
            const newAttribute = oldAttribute.replace(
              `child_${oldChildNum}`,
              `child_${currentChildNum + 1}`
            );
            itemsToUpdate[i].setAttribute(attrsToReplace[x], newAttribute);
          }
        }
      }

      const existingField =
        childSections[b].querySelectorAll(`input[type="hidden"]`) || "";

      if (!existingField.length) {
        childSections[b].appendChild(hiddenInput);
        hiddenInput.id = `child_${currentChildNum + 1}_entry`;
      }

      childSections[b].querySelector("[data-child-num]").innerHTML =
        currentChildNum + 1;
    }
    if (childSections.length >= LIMIT) {
      incrementSection.classList.add("display-none");
      limitReachedSection.classList.remove("display-none");
    } else {
      incrementSection.classList.remove("display-none");
      limitReachedSection.classList.add("display-none");
    }
  };

  if (addButton) {
    addButton.addEventListener("click", () => {
      const newChildFields = childFields.cloneNode(true);

      const fieldsToClear = newChildFields.querySelectorAll(
        `input:not([type="hidden"]), select`
      );
      for (let i = 0; i < fieldsToClear.length; i += 1) {
        if (
          fieldsToClear[i].type === "radio" ||
          fieldsToClear[i].type === "checkbox"
        ) {
          fieldsToClear[i].checked = false;
        } else {
          fieldsToClear[i].value = "";
        }
      }

      resetIds();
      additionalFieldsContainer.appendChild(newChildFields);
      resetIds();

      newChildFields.querySelector("input").focus();

      const deleteButton = newChildFields.querySelector(".delete-child-button");
      deleteButton.addEventListener("click", (e) => {
        e.preventDefault();

        const formBlock = deleteButton.parentNode.parentNode;
        formBlock.parentNode.removeChild(formBlock);

        resetIds();
      });
    });
  }
};
